import logdown from 'logdown';
import LinkedInTag from 'react-linkedin-insight';

const logger = logdown('aw-linkedinTracker');

export const CONVERSIONS = {
  CONTACT_US: {
    id: '1965922',
    docs: 'Contact us submitted',
  },
};

export const trackConversion = conversion => {
  logger.log(conversion.docs, { conversionId: conversion.id });
  LinkedInTag.track(conversion.id);
};
