import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { pages } from '@src/constants/pages';
import Layout from '@src/components/layout';
import ContactForm from '@src/components/contact/contactForm';

import adcoLogo from '@src/images/logo.svg';

import * as ConfigValues from '@src/helpers/configValues';

const Contact = () => (
  <Layout pageName={pages.contact.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">Contact us</h1>
      <div className="subheading">
        Let&lsquo;s get in touch for your next project
      </div>
    </div>
    <div className="v-contact h-pt-40">
      <section>
        <ContactForm />
        <aside itemScope itemType="http://schema.org/LocalBusiness">
          <h3>Our office</h3>
          <p>
            <span itemProp="name">ADCOproto</span>
            <span className="h-hidden"><LazyLoadImage itemProp="image" src={adcoLogo} /></span>
            <br />
            <span itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
              <span itemProp="streetAddress">
                2868 Bond Street
              </span>
              <br />
              <span itemProp="addressLocality">Rochester Hills</span>,{' '}
              <span itemProp="addressRegion">MI</span>{' '}
              <span itemProp="postalCode">48309</span>,{' '}
              <span itemProp="addressCountry">USA</span>
            </span>
            <br />
            <a href={`tel:${ConfigValues.getAdcoPhone()}`} itemProp="telephone">{ConfigValues.getAdcoPhone()}</a>
            <br />
            <a href={`mailto:${ConfigValues.getAdcoEmail()}`} itemProp="email">{ConfigValues.getAdcoEmail()}</a>
          </p>
        </aside>
      </section>
      <div className="c-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2935.162698811528!2d-83.1913709845349!3d42.636710679169326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c1bdd330ae3d%3A0x103a4f2c717a2c29!2s2868%20Bond%20St%2C%20Rochester%20Hills%2C%20MI%2048309!5e0!3m2!1sen!2sus!4v1583757993858!5m2!1sen!2sus" />
      </div>
    </div>
  </Layout>
);

export default Contact;
