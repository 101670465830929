import React from 'react';
import cx from 'classnames';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Reaptcha from 'reaptcha';
import urljoin from 'urljoin';
import logdown from 'logdown';

import * as ConfigValues from '@src/helpers/configValues';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';
import { CONVERSIONS, trackConversion } from '@src/helpers/linkedinTracker';

const logger = logdown('aw-contactForm');

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string().required('Required'),
});

class ContactForm extends React.Component {
  captcha = null;
  constructor() {
    super();
    this.state = {
      wasSubmitted: false,
      wasSubmissionSuccessful: false,
      submittedValues: {},
    };
  }

  handleVerify = async (recaptchaResponse, { setSubmitting, resetForm }) => {
    try {
      const response = await fetch(
        urljoin(ConfigValues.getAppUrl, 'contactEmail'),
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...this.state.submittedValues,
            recaptcha: recaptchaResponse,
          }),
        },
      );
      this.setState({ wasSubmissionSuccessful: response.ok });
      customEvent(events.contactMessage(true));
      resetForm();
    } catch (error) {
      logger.error(error.message || error);
      this.setState({ wasSubmissionSuccessful: false });
      customEvent(events.contactMessage(false));
    } finally {
      this.setState({ wasSubmitted: true, submittedValues: {} });
      this.captcha.reset();
      setSubmitting(false);
    }
  };

  handleSubmit = values => {
    this.setState({ submittedValues: values });
    this.captcha.execute();
    trackConversion(CONVERSIONS.CONTACT_US);
  };

  render() {
    const { wasSubmitted, wasSubmissionSuccessful } = this.state;

    return (
      <Formik
        initialValues={{ name: '', email: '', message: '', recaptcha: '' }}
        validationSchema={ValidationSchema}
        onSubmit={this.handleSubmit}
      >
        {formikProps => (
          <Form>
            <h3 className="h-mb-40">Send us a message</h3>
            <div
              className={cx('c-form-group', {
                'form-error':
                  formikProps.errors.name && formikProps.touched.name,
              })}
            >
              <Field
                name="name"
                type="text"
                className="c-form-control"
                id="name"
                placeholder="Name *"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="c-form-error"
              />
            </div>

            <div
              className={cx('c-form-group', {
                'form-error':
                  formikProps.errors.email && formikProps.touched.email,
              })}
            >
              <Field
                name="email"
                type="email"
                className="c-form-control"
                id="email"
                placeholder="Email address *"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="c-form-error"
              />
            </div>

            <div
              className={cx('c-form-group', {
                'form-error':
                  formikProps.errors.message && formikProps.touched.message,
              })}
            >
              <Field
                name="message"
                component="textarea"
                className="c-form-control"
                id="message"
                rows="8"
                placeholder="Message *"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="c-form-error"
              />
            </div>
            <div className="c-form-group-buttons">
              <div className="c-form-group">
                <Reaptcha
                  sitekey={ConfigValues.getRecaptchaSiteKey()}
                  onVerify={response =>
                    this.handleVerify(response, formikProps)
                  }
                  ref={e => (this.captcha = e)}
                  size="invisible"
                  badge="inline"
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="form-error"
                />
              </div>
              <button
                className="c-btn c-btn--primary"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                Submit
              </button>
            </div>
            {wasSubmitted && wasSubmissionSuccessful && (
              <div className="c-form-success">
                The message was sent! We will contact you shortly.
              </div>
            )}
            {wasSubmitted && !wasSubmissionSuccessful && (
              <div className="c-form-error">
                Due to unexpected technical issues we were not able to submit
                your message. Please contact us directly on{' '}
                <a
                  href={`mailto:${ConfigValues.getAdcoEmail()}`}
                  itemProp="email"
                >
                  {ConfigValues.getAdcoEmail()}
                </a>
                .
              </div>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

export default ContactForm;
